import React, { useEffect } from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import withSizes from 'react-sizes'
import get from 'lodash/get'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { media } from '../themes'
import { Default, Desktop, Tablet, Phone } from '../components/Responsive'
import { NextLink, PrevLink } from '../components/NavigationArrows'
import ImageFit from '../components/ImageFit'

const PhoneImageFit = styled(ImageFit)`
  padding-top: 20px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: center;

  ${media.phone`
    ${NextLink} {
      bottom: 70px;
      top: auto;
      right: 0;
    }

    ${PrevLink} {
      bottom: 70px;
      top: auto;
      left: 0;
    }
  `}
`

const Infos = styled.div`
  /* width: calc(${(props) => props.width} - 40px); */
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  width: 100%;

  ${media.phone`
    flex-direction: column;
  `}
`

const BackLink = styled(PrevLink)`
  position: static;
  height: 20px;
  width: 30px;
  /* top: auto; */
  /* left: auto; */
  margin: 0;
  /* flex-basis: 30px; */
  /* flex-grow: 0; */
  flex-shrink: 0;
  margin-right: 10px;
`
const TitleContainer = styled.div`
  flex: 1;
`
const Title = styled.h2`
  font-size: 1.1rem;
  padding: 0;
  margin: 0;
  font-weight: 400;

  a,
  a:hover a:focus,
  a:visited {
    color: inherit;
    text-decoration: inherit;
  }

  ${media.phone`
    /* font-size: 0.8rem;
    line-height: 1rem;
    letter-spacing: initial; */
    order: 2;
  `}
`

const Year = styled.div`
  font-size: 1.5rem;
  color: #c5c5c5;
  font-weight: lighter;
  letter-spacing: 3px;

  ${media.phone`
    /* font-size: 0.8rem;
    line-height: 1rem;
    letter-spacing: initial; */
    order: -1;
  `}
`

const LabelStyle = css`
  color: #c5c5c5;
  font-size: 0.8rem;
  line-height: 1rem;
`

const PaintingDescription = styled.div`
  ${LabelStyle};
`

const PaintingSizes = styled.div`
  ${LabelStyle};
`

const PaintingPageTemplate = ({
  data,
  pageContext,
  windowWidth,
  windowHeight,
}) => {
  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  })

  const post = data.sanityPaintings
  const {
    sizes,
    real_sizes,
    composition,
    no_frame_shadow,
    sizes_description,
  } = post
  const { next, previous } = pageContext
  const slug = get(post, 'slug.current') || post.id
  let sizeWidth = sizes.width
  let sizeHeight = sizes.height

  if (real_sizes && real_sizes.width && real_sizes.height) {
    sizeWidth = real_sizes.width
    sizeHeight = real_sizes.height
  }

  // const gutter = 150

  // const dst = {
  //   width: windowWidth - gutter,
  //   height: windowHeight - gutter,
  // }

  // const src = {
  //   width: sizeWidth,
  //   height: sizeHeight,
  // }

  // const scale = Math.min(dst.width / src.width, dst.height / src.height)

  // const paintingWidth = sizeWidth * scale + 'px'
  const PaintingImageFit = (
    <ImageFit
      width={sizeWidth}
      height={sizeHeight}
      offsetWidth="150"
      offsetHeight="150"
    >
      <Img
        sizes={get(post, 'mainImage.asset.fluid')}
        alt={post.title}
        // imgStyle={{ width: w, height: h }}
        style={{
          //   margin: '0 auto',
          //   width: w,
          //   height: h,
          boxShadow: no_frame_shadow === true ? null : '0px 2px 6px #616161',
        }}
      />
    </ImageFit>
  )

  return (
    <Layout>
      <Content>
        <Phone>
          <PhoneImageFit
            width={sizeWidth}
            height={sizeHeight}
            offsetWidth="20"
            offsetHeight="170"
          >
            <Img
              sizes={get(post, 'mainImage.asset.fluid')}
              alt={post.title}
              imgStyle={{ margin: '0 auto' }}
              style={{
                margin: '0 auto',
                //   width: w,
                //   height: h,
                boxShadow:
                  no_frame_shadow === true ? null : '0px 2px 6px #616161',
              }}
            />
          </PhoneImageFit>
        </Phone>
        <Tablet>{PaintingImageFit}</Tablet>
        <Desktop>{PaintingImageFit}</Desktop>
        <Default>{PaintingImageFit}</Default>

        {/* <Phone>


        </Phone> */}

        {/* <ImageContainer width={paintingWidth}>
          <Img
            sizes={get(post, 'mainImage.localFile.childImageSharp.sizes')}
            alt={post.title}
            // imgStyle={{ width: w, height: h }}
            style={{
              //   margin: '0 auto',
              //   width: w,
              //   height: h,
              boxShadow:
                no_frame_shadow === true ? null : '0px 2px 6px #616161',
            }}
          />
        </ImageContainer> */}

        <Infos>
          <BackLink>
            <Link to={`/paintings/#${slug}`}>back</Link>
          </BackLink>
          <TitleContainer>
            <Title>
              <Link to={`/paintings/#${slug}`}>{post.title}</Link>
            </Title>
            <PaintingDescription>{composition}</PaintingDescription>
            <PaintingSizes>
              {sizes_description
                ? sizes_description
                : `${sizes.height}x${sizes.width}`}
            </PaintingSizes>
          </TitleContainer>
          <Year>{post.year}</Year>
        </Infos>

        {next && (
          <PrevLink>
            <Link to={`/painting/${next.slug}`}>{next.title}</Link>
          </PrevLink>
        )}
        {previous && (
          <NextLink>
            <Link to={`/painting/${previous.slug}`}>{previous.title}</Link>
          </NextLink>
        )}
      </Content>
    </Layout>
  )
}

const mapSizesToProps = ({ width, height }) => {
  return { windowWidth: width, windowHeight: height }
}

export default withSizes(mapSizesToProps)(PaintingPageTemplate)

export const query = graphql`
  query($id: String!) {
    sanityPaintings(id: { eq: $id }) {
      id
      year
      title
      slug {
        current
      }
      no_frame_shadow
      composition
      sizes {
        _type
        height
        width
      }
      real_sizes {
        _type
        height
        width
      }
      sizes_description
      mainImage {
        _type
        asset {
          fluid(maxWidth: 1200) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
