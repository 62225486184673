import styled, { css } from 'styled-components'

import { media } from '../themes'

import ArrowLeftIcon from '../assets/icons/arrow-left.svg'
import ArrowRightIcon from '../assets/icons/arrow-right.svg'

const ArrowStyle = css`
  background: no-repeat center center;
  background-size: contain;
  top: 50%;
  margin-top: -25px;
  width: 50px;
  height: 50px;
  text-indent: -9999px;
  overflow: hidden;
  position: fixed;

  a {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    text-decoration: none;
    color: black;
  }

  a:visited {
    color: black;
  }
`

export const NextLink = styled.div`
  ${ArrowStyle}
  background-image: url(${ArrowRightIcon});
  right: 20px;  
`

export const PrevLink = styled.div`
  ${ArrowStyle}
  background-image: url(${ArrowLeftIcon});
  left: 20px;  
`
